import React from "react";

const LoginTable = ({ username, password }) => {
	return (
		<div className=''>
					</div>
	);
};

export default LoginTable;
